// Import JSON file
import glyphs from './glyphs.json';

// Icon component
const Icon = ({ height, name, adjustSize, size = 24, color = 'currentColor',className, adjust, viewBox='0 0 1024 1024' }) => {
  const glyph = glyphs.find(g => g.name === name);
  if (!glyph) return null;

  return (
    <svg
      className={className}
      width={adjustSize ? adjustSize : size}
      height={height ? height : 30}
      viewBox={viewBox}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={glyph.path} />
    </svg>
  );
};

export default Icon;
