import React, {useState, useEffect} from 'react';
import { fetchWithDefaults } from '../../../config/fetchDefault';
import { getCurrentAge, dobFormat, validDOB } from '../../../utilities/timeFunctions';
import Icon from '../../../assets/icons/icon.js'

function QualifierSection({lead, data, setLead, agentId, handleSetProfile}) {
    const [borrowerDate, setBorrowerDate] = useState('');
    const [borrowerError, setBorrowerError] = useState('');
    const [coBorrowerError, setCoBorrowerError] = useState('');
    const [coBorrowerDate, setCoBorrowerDate] = useState(false);
    const [showDOBTooltip, setShowDOBToolTip] = useState(false);
    const handleChange = (event, column) => {
        const { value } = event.target;
        setLead(column, value);
    };
    const parseWeight = (input) => {
        // Use a regular expression to extract the numeric part
        const parsedWeight = parseInt(input.match(/\d+/), 10);
        return parsedWeight;
    };
    const handleBlur = async (value, column, leadId) => {
        //const { value } = event.target;
        let newValue=value;
        if(column==='weight'){
            newValue=parseWeight(value);
        }
        handleSetProfile(column, newValue);

        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    agent:agentId,
                    lead:leadId,
                    column:column,
                    value: newValue
                })
            };

            const response = await fetchWithDefaults('/updateLead', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const dobChange = (val, column) => {
        const value = val.replace(/\D/g, ''); // Remove non-digit characters
        const formattedDate = formatDate(value);
        if(column==='borrower_dob'){
            setBorrowerDate(formattedDate);
            setLead('borrower_dob', formattedDate)
        }
        else{
            setCoBorrowerDate(formattedDate);
        }
    };

    const formatDate = (value) => {
        // Automatically add slashes as the user types
        let formattedValue = value;
        if (value.length >= 5) {
            formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4, 8)}`;
        } else if (value.length >= 3) {
            formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
        }

        // Validate and ensure it's a valid date
        const isCompleteDate = formattedValue.length === 10; // MM/DD/YYYY is 10 characters
        if (isCompleteDate) {
            const [month, day, year] = formattedValue.split('/');
            const validDate = new Date(`${year}-${month}-${day}T00:00:00Z`); // Use UTC to avoid timezone issues

            // Check if the parsed date matches the input
            if (
                validDate &&
                validDate.getUTCFullYear() === parseInt(year, 10) &&
                validDate.getUTCMonth() + 1 === parseInt(month, 10) &&
                validDate.getUTCDate() === parseInt(day, 10)
            ) {
                return `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`;
            } else {
                return "Invalid date"; // Optionally handle invalid dates
            }
        }

        return formattedValue;
    };
    const handleDateBlur=(event, column, leadId)=>{
        const valididy=validDOB(event.target.value);
        if(valididy.isValid){
            if(column==='borrower_dob'){
                setBorrowerError('');
            }
            else{
                setCoBorrowerError('');
            }
            const age=getCurrentAge(event.target.value);
            const newColumn=column==='borrower_dob' ? 'borrower_age' : 'co_borrower_age';
            handleBlur(event.target.value, column, leadId);
            if(valididy.date !== ''){
                setLead(newColumn, age);
                handleBlur(age, newColumn, leadId);
            }
        }
        else{
            if(column==='borrower_dob'){
                setBorrowerError(valididy.error);
            }
            else{
                setCoBorrowerError(valididy.error);
            }
        }
    }

    useEffect(() => {
        setCoBorrowerDate(dobFormat(lead[0].co_borrower_dob ? lead[0].co_borrower_dob : ''));
        setBorrowerDate(dobFormat(lead[0].borrower_dob ? lead[0].borrower_dob : ''));
    }, [])
    return (
        <div className="qualifierSection">
            {data && data.map((item, index) => (
                <div className="qualifierFieldWrapper" key={`qualifierField${index}`}>
                    <div className="qualifierFieldTitle">
                        {item.name} {
                            item.column==='borrower_dob' ?
                                <>
                                    <div
                                        style={{display:'inline-block'}}
                                        onMouseEnter={() => setShowDOBToolTip(true)}
                                        onMouseLeave={() => setShowDOBToolTip(false)}
                                    >
                                        <Icon
                                            name={'info-circle-q'}
                                            height={12}
                                            size={12}
                                            viewBox='-10 0 1024 700'
                                            color="#45bcaa"
                                        ></Icon>
                                    </div>

                                    <span className="error">{borrowerError}</span>
                                </>
                            : item.column==='co_borrower_dob' ?
                                    <span className="error">{borrowerError}</span>
                            : ''
                        }
                    </div>
                    {item.column==='borrower_dob' && showDOBTooltip && (
                        <div className="qualifierFieldTitle" style={{width: 'max-content'}}>
                            Enter DOB for most accurate recommendations and pricing
                        </div>
                    )}
                    {item.type==='drop' ? (
                        <select className="outlineButton filterButton" onChange={(event)=>[handleChange(event, item.column),handleBlur(event.target.value, item.column, lead[0].lead_id)]} value={lead[0][item.column]}>
                            {item.options && item.options.map((option, index) => (
                                <option value={option.value} key={index}>{option.title}</option>
                            ))}

                        </select>
                    ) : item.column === 'borrower_dob' ? (
                        <input
                            type="text"
                            className="qualifierFieldInput"
                            placeholder='mm/dd/yyyy'
                            value={borrowerDate}
                            onChange={(event) => dobChange(event.target.value, item.column)}
                            onBlur={(event) => handleDateBlur(event, item.column, lead[0].lead_id)}
                        />
                    ) : item.column === 'co_borrower_dob' ? (
                        <input
                            type="text"
                            className="qualifierFieldInput"
                            placeholder='mm/dd/yyyy'
                            value={coBorrowerDate}
                            onChange={(event) => dobChange(event.target.value, item.column)}
                            onBlur={(event) => handleDateBlur(event, item.column, lead[0].lead_id)}
                        />
                    ) : item.column === 'borrower_age' ? (
                        <input
                            type="text"
                            className="qualifierFieldInput"
                            value={lead[0][item.column]}
                            onChange={(event) => handleChange(event, item.column)}
                            onBlur={(event) => handleBlur(event.target.value, item.column, lead[0].lead_id)}
                            disabled={borrowerDate !== ''}
                        />
                    ) : (
                        <input
                            type="text"
                            className="qualifierFieldInput"
                            value={lead[0][item.column]}
                            onChange={(event) => handleChange(event, item.column)}
                            onBlur={(event) => handleBlur(event.target.value, item.column, lead[0].lead_id)}
                        />
                    )}
                </div>
            ))}
        </div>
    );
}

export default QualifierSection;
