import React, {useState} from 'react';
import Icon from '../../../assets/icons/icon';


function CallbackDetail({details,formatDate,openLead}) {
    const [open, setOpen] = useState(false);
    const openUp=()=>{
        setOpen((prevOpen) => !prevOpen);
    }
    return (
        <>
            <div className="quilityNestedBlockWrapper" onClick={(event) => { event.stopPropagation();openUp();}}>
                <div className='quilityNestedBlockHeader'>
                    <div className="quilityParentBlockTitle" style={{width: '170px'}}>Call Backs</div>
                    <span style={{marginLeft:'20px'}}>
                        <span className="typeBlock">{details.length} Callback{details.length > 1 || details.length < 1 ? 's': ''}</span>
                    </span>
                    <span className={`quilityBlockIcon ${open ? 'rotate':''}`} style={{marginTop: '-5px', transition:'all .5s'}}><span className="fontello"><Icon name={'q-up'} size={20} className="closeIcon" adjust="fontello" /></span></span>
                </div>

                <div className={`detailWrapper ${open ? 'openUp' : ''}`}>
                    {details.map((detail, index) => (
                        <div className="detailItem" key={"callback"+index}>
                            <span className="detailTitle">{detail.schedLeadFirst} {detail.schedLeadLast}</span>
                            <span className="detailDate">{formatDate(detail.schedTime)}</span>
                            <span className="detailTimezone"></span>
                            <span className="ViewLead" onClick={(event) => { event.stopPropagation(); openLead(detail.schedLead); }}>View Lead</span>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default CallbackDetail;