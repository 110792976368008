import React, { useState, useEffect, useRef } from 'react';
import '../../css/signup.css';
import image from '../../assets/icons/QuilitySwitchboardFunnel.svg';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Payment from './payment';
import image2 from '../../assets/icons/loading-loader.gif';
import Icon from '../../assets/icons/icon';

let stripePromise
let stripeProductId

if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production') {
    stripePromise = loadStripe('pk_test_RGmoYxMaWO1N2K3JqJRTAFPQ');
    stripeProductId = 'prod_Qtk7gb2UmxSwsI'
} else {
    stripePromise = loadStripe('pk_live_7MWtwibC2i1DeI913AW7Y8ln');
    stripeProductId = 'prod_RLNupiQMKkzIk6' // need to get production id
}

function Signup({ }) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const agency = urlParams.get('organization')?.replaceAll("\"","")
    const hasAgency = agency !== null && agency !== undefined && agency !== ''

    const [terms, setTerms] = useState(false);
    const [focusedField, setFocusedField] = useState('');
    const [searched, setSearched] = useState(false);
    const [imoInfo, setImoInfo] = useState(false);
    const [noExist, setNoExist] = useState(false);
    const [secret, setSecret] = useState(false);
    const [loading, setLoading] = useState(false);
    const [usernameCheck, setUsernameCheck] = useState(null);
    const [billingAgreement, setBillingAgreement] = useState(false);
    const [alreadyExists, setAlreadyExists] = useState(false)
    const [stripePriceId, setStripePriceId] = useState(null)

    const [errors, setErrors] = useState({
        "passwordLength": "At least 10 characters long.",
        "passwordCapital": "At least one uppercase letter.",
        "passwordNumber": "At least one number.",
        "passwordSpecial": "At least one special character.",
        "passwordMatch": "Passwords do not match.",
        "phoneNumberLength": "The phone number must be 10 digits."
    });

    const [canSubmit, setCanSubmit] = useState(false);
    const [submitToStripe, setSubmitToStripe] = useState(false)

    const options = {
        // passing the client secret obtained from the server
        clientSecret: secret,
    };
    const searchImo = async () => {
        setLoading(true)
        setAlreadyExists(false);
        setNoExist(false);
        const workEmail = formData['Work_Email'];
        try {
            const response = await fetchWithDefaults(`/registration/lookupImo?email=${workEmail}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setLoading(false)
            if (jsonData.status !== false) {
                setImoInfo(jsonData[0]);
                handleInputChange('First_Name', jsonData[0].FirstName);
                handleInputChange('Last_Name', jsonData[0].LastName);
                handleInputChange('Phone_Number', jsonData[0].AgentPhone);
                handleInputChange('Name_on_Card', jsonData[0].AgentName);
                handleInputChange('Address', jsonData[0].StreetAddress);
                handleInputChange('City', jsonData[0].City);
                handleInputChange('State', jsonData[0].State);
                handleInputChange('Zip', jsonData[0].Zip);
                if (jsonData[1].exists) {
                    setAlreadyExists(true)
                }
                else {
                    setSearched(true);
                }
            }
            else {
                setNoExist(true)
                setSearched(true);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    }
    const gatherSecret = async () => {

        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    amount: 5000,
                })
            };

            const response = await fetchWithDefaults('/registration/getStripeSecret', requestOptions);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
            setSecret(jsonData.client_secret);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const handleFocus = (item) => {
        setFocusedField(item);
    };

    const handleBlur = () => {
        setFocusedField('');
    };
    const [formData, setFormData] = useState(
        {
            Work_Email: '',
            Username: '',
            First_Name: '',
            Last_Name: '',
            Phone_Number: '',
            Password: '',
            Confirm_Password: '',
            Time_Zone: '',
            Name_on_Card: '',
            Address: '',
            City: '',
            State: '',
            Zip: '',
        }
    );

    const handleInputChange = (item, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [item]: value,
        }));
        if (item === 'Password' || item === 'Confirm_Password') {
            validatePassword(item, value);
        }
    };
    const validatePassword = (field, value) => {
        let errorMessages = {};

        if (field === 'Password') {
            const password = value;
            if (password.length < 10) {
                errorMessages.passwordLength = 'At least 10 characters long.';
            }
            if (!/[A-Z]/.test(password)) {
                errorMessages.passwordCapital = 'At least one uppercase letter.';
            }
            if (!/[0-9]/.test(password)) {
                errorMessages.passwordNumber = 'At least one number.';
            }
            if (!/[!@#$%^&*]/.test(password)) {
                errorMessages.passwordSpecial = 'At least one special character.';
            }
        }

        if (field === 'Confirm_Password' || formData['Password']) {
            if (formData['Password'] !== value) {
                errorMessages.passwordMatch = 'Passwords do not match.';
            }
        }

        if (errors.phoneNumberLength) {
            errorMessages['phoneNumberLength'] = errors.phoneNumberLength
        }

        setErrors((prevErrors) => ({
            ...errorMessages
        }));
    };
    const handleUsername = async () => {
        try {
            const user = formData['Username'];
            setLoading(true);
            const response = await fetchWithDefaults(`/registration/searchUsername?username=${user}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setLoading(false);
            if (jsonData === false) {

                setUsernameCheck(false);
            }
            else {
                setUsernameCheck(true);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    }

    const phoneRef = useRef()
    const phoneInputMask = (phoneVal = null) => {
        let initPhoneValue
        let errorMessages = {}

        // get the current phone value
        if (phoneVal !== null) {
            initPhoneValue = phoneVal
        } else {
            initPhoneValue = phoneRef.current.value
        }

        // if longer than 10 digits then
        // remove non-numeric characters and
        // only keep the first 10 characters
        if (phoneRef.current.value.replace(/(\D)/g, '').length > 10) {
            initPhoneValue = initPhoneValue.replace(/(\D)/g, '').substring(0, 10)
        }

        // apply a regex to get the phone number data in a state that we can work with
        // remove the first element from the array since it contains the string the regex opperated on
        // join the array with white space as the seperator and set the resulting string as our phone number
        phoneRef.current.value = initPhoneValue.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/).slice(1).join(' ')

        // get the digits of the phone number value and
        // pass to handleInputChange method so that formData is updated
        // with the correct value
        const unmaskedPhoneNumber = phoneRef.current.value.replace(/(\D)/g, '');
        handleInputChange('Phone_Number', unmaskedPhoneNumber)

        // making sure not to overwrite any exisitng error messages
        for (const key in errors) {
            errorMessages[key] = errors[key]
        }

        if (unmaskedPhoneNumber.length !== 10) {
            errorMessages['phoneNumberLength'] = 'The phone number must be 10 digits.'
        } else {
            errorMessages = Object.keys(errorMessages).filter(key =>
                key !== 'phoneNumberLength').reduce((obj, key) => {
                    obj[key] = errorMessages[key];
                    return obj;
                }, {}
                )
        }

        setErrors((prevErrors) => ({
            ...errorMessages
        }));
    }
    const [productInfo, setProductInfo] = useState({
        name: null,
        price: null,
        call_min: null,
        multi_media_msg: null,
        out_going_caller_ids: null,
        overage_call_min: null,
        overage_multi_media_msg: null,
        overage_text_msg: null,
        text_msg: null
    });

    const getProductData = async () => {

        try {
            const res = await fetchWithDefaults(`/lookupProduct?product=${stripeProductId}`)
            const data = await res.json()

            // console.log('stripe product info', data)

            setProductInfo({
                name: data.name,
                price: (data.default_price.unit_amount * 0.01).toFixed(2),
                call_min: data.metadata.minutes ?? null,
                multi_media_msg: data.metadata.mms ?? null,
                text_msg: data.metadata.sms ?? null,
                out_going_caller_ids: data.metadata.out_going_calls ?? null,
                overage_call_min: data.metadata.overage_minutes ?? null,
                overage_multi_media_msg: data.metadata.overage_mms ?? null,
                overage_text_msg: data.metadata.overage_sms ?? null,
            })

            setStripePriceId(data.default_price.id)

        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (!secret) {
            setSecret(true);
        }
        if (secret === true) {
            gatherSecret();
            getProductData()
        }
        const getTimeZone = () => {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            handleInputChange('Time_Zone', timeZone);
        };

        getTimeZone();

        if (searched === true) {
            phoneInputMask(formData['Phone_Number'])
        }
    }, [secret, searched])

    useEffect(() => {
        let pass = true
        const formKeys = Object.keys(formData)

        // console.log('[Signup] - Performing validation checks')

        if (Object.keys(errors).length > 0 ||
            terms === false ||
            billingAgreement === false ||
            hasAgency === false
        ) {
            // console.log('[Signup] - Validaition: Errors', errors)
            pass = false
        } else {
            for (let i = 0;i < formKeys.length;i++) {
                const key = formKeys[i]

                if (formData[key] === '' || formData[key] === null || formData[key] === undefined) {
                    pass = false

                    // console.log('[Signup] - Validaition: Missing Form Field', key)
                    break
                }

                if (key === 'Zip' && formData['Zip'] !== '' && formData['Zip'].length !== 5) {
                    pass = false

                    // console.log('[Signup] - Validaition: Invalid zipcode', formData['Zip'])
                    break
                }
            }
        }

        setCanSubmit(pass)
    }, [errors, formData, terms, billingAgreement])

    const signupStates = [
        {
            "name": "",
            "abbreviation": ""
        },
        {
            "name": "Alabama",
            "abbreviation": "AL"
        },
        {
            "name": "Alaska",
            "abbreviation": "AK"
        },
        {
            "name": "Arizona",
            "abbreviation": "AZ"
        },
        {
            "name": "Arkansas",
            "abbreviation": "AR"
        },
        {
            "name": "California",
            "abbreviation": "CA"
        },
        {
            "name": "Colorado",
            "abbreviation": "CO"
        },
        {
            "name": "Connecticut",
            "abbreviation": "CT"
        },
        {
            "name": "Delaware",
            "abbreviation": "DE"
        },
        {
            "name": "District Of Columbia",
            "abbreviation": "DC"
        },
        {
            "name": "Florida",
            "abbreviation": "FL"
        },
        {
            "name": "Georgia",
            "abbreviation": "GA"
        },
        {
            "name": "Hawaii",
            "abbreviation": "HI"
        },
        {
            "name": "Idaho",
            "abbreviation": "ID"
        },
        {
            "name": "Illinois",
            "abbreviation": "IL"
        },
        {
            "name": "Indiana",
            "abbreviation": "IN"
        },
        {
            "name": "Iowa",
            "abbreviation": "IA"
        },
        {
            "name": "Kansas",
            "abbreviation": "KS"
        },
        {
            "name": "Kentucky",
            "abbreviation": "KY"
        },
        {
            "name": "Louisiana",
            "abbreviation": "LA"
        },
        {
            "name": "Maine",
            "abbreviation": "ME"
        },
        {
            "name": "Maryland",
            "abbreviation": "MD"
        },
        {
            "name": "Massachusetts",
            "abbreviation": "MA"
        },
        {
            "name": "Michigan",
            "abbreviation": "MI"
        },
        {
            "name": "Minnesota",
            "abbreviation": "MN"
        },
        {
            "name": "Mississippi",
            "abbreviation": "MS"
        },
        {
            "name": "Missouri",
            "abbreviation": "MO"
        },
        {
            "name": "Montana",
            "abbreviation": "MT"
        },
        {
            "name": "Nebraska",
            "abbreviation": "NE"
        },
        {
            "name": "Nevada",
            "abbreviation": "NV"
        },
        {
            "name": "New Hampshire",
            "abbreviation": "NH"
        },
        {
            "name": "New Jersey",
            "abbreviation": "NJ"
        },
        {
            "name": "New Mexico",
            "abbreviation": "NM"
        },
        {
            "name": "New York",
            "abbreviation": "NY"
        },
        {
            "name": "North Carolina",
            "abbreviation": "NC"
        },
        {
            "name": "North Dakota",
            "abbreviation": "ND"
        },
        {
            "name": "Ohio",
            "abbreviation": "OH"
        },
        {
            "name": "Oklahoma",
            "abbreviation": "OK"
        },
        {
            "name": "Oregon",
            "abbreviation": "OR"
        },
        {
            "name": "Pennsylvania",
            "abbreviation": "PA"
        },
        {
            "name": "Rhode Island",
            "abbreviation": "RI"
        },
        {
            "name": "South Carolina",
            "abbreviation": "SC"
        },
        {
            "name": "South Dakota",
            "abbreviation": "SD"
        },
        {
            "name": "Tennessee",
            "abbreviation": "TN"
        },
        {
            "name": "Texas",
            "abbreviation": "TX"
        },
        {
            "name": "Utah",
            "abbreviation": "UT"
        },
        {
            "name": "Vermont",
            "abbreviation": "VT"
        },
        {
            "name": "Virginia",
            "abbreviation": "VA"
        },
        {
            "name": "Washington",
            "abbreviation": "WA"
        },
        {
            "name": "West Virginia",
            "abbreviation": "WV"
        },
        {
            "name": "Wisconsin",
            "abbreviation": "WI"
        },
        {
            "name": "Wyoming",
            "abbreviation": "WY"
        }
    ]

    const signupTimezones = [
        {
            label: '',
            value: '',
        },
        {
            label: 'Puerto Rico Time (Atlantic Standard Time)',
            value: 'America/Puerto_Rico',
        },
        {
            label: 'Eastern Time',
            value: 'America/New_York',
        },
        {
            label: 'Central Time',
            value: 'America/Chicago',
        },
        {
            label: 'Mountain Time',
            value: 'America/Denver',
        },
        {
            label: 'Arizona Time (Mountain Standard Time, no daylight saving)',
            value: 'America/Phoenix',
        },
        {
            label: 'Pacific Time',
            value: 'America/Los_Angelas',
        },
        {
            label: 'Alaska Time',
            value: 'America/Anchorage',
        },
        {
            label: 'Hawaii Time',
            value: 'Pacific/Honolulu',
        },
    ]

    const handleTermsCheckbox = (e) => {
        if (e.target.checked === true) {
            window.open('https://hq.quility.com/api/public/document/315272/view/general-terms-of-service', '_blank')
        }

        setTerms(!terms);
    }

    const showTerms = () => {
        if (!terms) {
            setTerms(true)
        }

        window.open('https://hq.quility.com/api/public/document/315272/view/general-terms-of-service', '_blank')
    }

    return (
        <div className="completeSignUpWrapper">
            {loading && (
                <div className="loadingScreen">
                    <div className="loaderWrapper"><img src={image2} alt="Loading Gif" style={{ width: '100%' }} /></div>
                </div>
            )}

            <div className="signUpWrapper">
                <div className={`frontPageQuilityParentBlockWrapper`} style={{ marginTop: '70px', padding: '32px 24px' }}>
                    <div className="signupLogo">
                        <img src={image} alt="Main Logo" className="signupLogo" />
                    </div>

                    <div style={{ color: 'black', fontSize: 26, fontFamily: 'Montserrat', fontWeight: '700', wordWrap: 'break-word', paddingBlock: '24px' }}>Create New Account</div>

                    {!hasAgency && (
                        <div className='noExist'>Cannot creat account. Organization is missing!</div>
                    )}

                    <div className='contentWrapper'>
                        <div className='contentTitleWrapper'>
                            <div className='contentTitle'>Account Information</div>
                        </div>
                        <div className='conentBodyWrapper'>
                            <div className='contentBodyInputLabel' style={{ alignSelf: 'stretch' }}>Please note: All fields are required.</div>
                            <div style={{ alignSelf: 'stretch', height: 137, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex' }}>
                                <div style={{ alignSelf: 'stretch', color: '#2F2F2F', fontSize: 18, fontFamily: 'Montserrat', fontWeight: '700', wordWrap: 'break-word' }}>Find Your Account</div>
                                <div style={{ background: 'white', borderRadius: 8, justifyContent: 'flex-start', alignItems: 'flex-end', gap: 20, display: 'inline-flex' }}>
                                    <div className='contentBodyInnerFlexNestedInputWrapper' style={{ width: 643 }}>
                                        <div className='contentBodyInputLabelWrapper'>
                                            <div className='contentBodyInputLabel'>Work Email</div>
                                        </div>
                                        <input
                                            type="text"
                                            className={`qualifierFieldInput shorten`}
                                            value={formData['Work_Email']}
                                            onChange={(e) => handleInputChange('Work_Email', e.target.value)}
                                        />
                                        {noExist && (
                                            <div className='conentBodyInputError'>This Email was not found in your IMO. Please try the email address that you use to log in to work platforms and complete fields below manually if needed.</div>
                                        )}
                                    </div>
                                    <div onClick={() => searchImo()} style={{ cursor: 'pointer', width: 100, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex' }}>
                                        <div style={{ alignSelf: 'stretch', paddingLeft: 16, paddingRight: 16, paddingTop: 14, paddingBottom: 14, background: '#46C3B2', borderRadius: 6, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                            <div className='contentButtonLabel'>Find Me</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex' }}>
                                <div style={{ width: 763, height: 13, color: '#2F2F2F', fontSize: 18, fontFamily: 'Montserrat', fontWeight: '700', wordWrap: 'break-word' }}>Profile Details</div>
                                <div className='contentBodyNestedInputWrapper'>
                                    <div className='contentBodyInputLabelWrapper'>
                                        <div className="contentBodyInputLabel">Username</div>
                                    </div>
                                    <input
                                        className="qualifierFieldInput"
                                        value={formData['Username']}
                                        onChange={(e) => handleInputChange('Username', e.target.value)}
                                        onBlur={handleUsername}
                                    />
                                    {usernameCheck === true && (
                                        <div className='conentBodyInputError'>This username is already in use, please choose a new one.</div>
                                    )}
                                </div>
                                <div className='contentBodyOuterNestedInputWrapper'>
                                    <div className='contentBodyInnerFlexNestedInputWrapper' style={{ flex: '1 1 0' }}>
                                        <div className='contentBodyInputLabelWrapper'>
                                            <div className="contentBodyInputLabel">First Name</div>
                                        </div>
                                        <input
                                            className="qualifierFieldInput"
                                            value={formData['First_Name']}
                                            onChange={(e) => handleInputChange('First_Name', e.target.value)}
                                        />
                                    </div>
                                    <div className='contentBodyInnerFlexNestedInputWrapper' style={{ flex: '1 1 0' }}>
                                        <div className='contentBodyInputLabelWrapper'>
                                            <div className="contentBodyInputLabel">Last Name</div>
                                        </div>
                                        <input
                                            className="qualifierFieldInput"
                                            value={formData['Last_Name']}
                                            onChange={(e) => handleInputChange('Last_Name', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='contentBodyOuterNestedInputWrapper'>
                                    <div className='contentBodyInnerFlexNestedInputWrapper' style={{ flex: '1 1 0' }}>
                                        <div className='contentBodyInputLabelWrapper'>
                                            <div className="contentBodyInputLabel">Phone Number</div>
                                        </div>
                                        <input
                                            ref={phoneRef}
                                            className="qualifierFieldInput"
                                            onChange={() => phoneInputMask()}
                                            onFocus={() => handleFocus('Phone_Number')}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                                        <div className='contentBodyNestedInputWrapper'>
                                            <div className='contentBodyInputLabelWrapper'>
                                                <div className="contentBodyInputLabel">Time Zone</div>
                                            </div>

                                            <select className='contentBodySelect' onChange={(e) => handleInputChange('Time_Zone', e.target.value)} value={formData['Time_Zone']}>
                                                {signupTimezones.map((tz, index) => (
                                                    <option value={tz.value} key={`signuptimezone${index}`}>{tz.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='contentBodyOuterNestedInputWrapper'>
                                    <div className='contentBodyInnerFlexNestedInputWrapper' style={{ flex: '1 1 0' }}>
                                        <div className='contentBodyInputLabelWrapper'>
                                            <div className="contentBodyInputLabel">Password</div>
                                        </div>
                                        <input
                                            type='password'
                                            className="qualifierFieldInput"
                                            value={formData['Password']}
                                            onChange={(e) => handleInputChange('Password', e.target.value)}
                                            onFocus={() => handleFocus('Password')}
                                            onBlur={handleBlur}
                                        />
                                        {formData['Password'].length > 0 && Object.keys(errors).length > 0 && (
                                            <div style={{ textAlign: 'left' }}>
                                                {errors.passwordLength && <div className='conentBodyPasswordError'>{errors.passwordLength}</div>}
                                                {errors.passwordCapital && <div className='conentBodyPasswordError'>{errors.passwordCapital}</div>}
                                                {errors.passwordNumber && <div className='conentBodyPasswordError'>{errors.passwordNumber}</div>}
                                                {errors.passwordSpecial && <div className='conentBodyPasswordError'>{errors.passwordSpecial}</div>}
                                                {errors.passwordMatch && <div className='conentBodyPasswordError'>{errors.passwordMatch}</div>}
                                            </div>
                                        )}
                                    </div>
                                    <div className='contentBodyInnerFlexNestedInputWrapper' style={{ flex: '1 1 0' }}>
                                        <div className='contentBodyInputLabelWrapper'>
                                            <div className="contentBodyInputLabel">Confirm Password</div>
                                        </div>
                                        <input
                                            type='password'
                                            className="qualifierFieldInput"
                                            value={formData['Confirm_Password']}
                                            onChange={(e) => handleInputChange('Confirm_Password', e.target.value)}
                                            onFocus={() => handleFocus('Confirm_Password')}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='contentWrapper'>
                        <div className='contentTitleWrapper'>
                            <div className='contentTitle'>Billing Information</div>
                        </div>
                        <div className="pricingGrid" style={{ margin: '24px auto' }}>
                            <div id='productDetailGrid'>
                                <div className='pricingGridLeft' style={{ margin: 'auto' }}>
                                    <div>
                                        <span style={{ color: '#2F2F2F', fontSize: 14, fontFamily: 'Montserrat', fontWeight: '500', wordWrap: 'break-word' }}>Switchboard</span>
                                        <span style={{ color: '#2F2F2F', fontSize: 14, fontFamily: 'Montserrat', fontWeight: '500', wordWrap: 'break-word' }}>®</span>
                                        <span style={{ color: '#2F2F2F', fontSize: 14, fontFamily: 'Montserrat', fontWeight: '500', wordWrap: 'break-word' }}> Funnel</span>
                                    </div>
                                    <div>
                                        <span style={{ color: '#2F2F2F', fontSize: 32, fontFamily: 'Montserrat', fontWeight: '500', wordWrap: 'break-word' }}>$</span>
                                        <span style={{ color: '#2F2F2F', fontSize: 32, fontFamily: 'Montserrat', fontWeight: '500', wordWrap: 'break-word' }}>{productInfo.price}</span>
                                        <span style={{ color: '#8D8D8D', fontSize: 14, fontFamily: 'Montserrat', fontWeight: '500', wordWrap: 'break-word' }}> monthly</span>
                                    </div>
                                </div>
                                <div className='pricingGridRight' style={{ margin: 'auto', textAlign: 'left' }}>
                                    <div style={{ width: '100%', color: '#2F2F2F', fontSize: 16, fontFamily: 'Montserrat', fontWeight: '700', wordWrap: 'break-word' }}>Monthly Subscription Includes</div>
                                    <div id='productFeaturesGrid'>
                                        {productInfo.call_min !== null && (
                                            <>
                                                <div>
                                                    <Icon className={'qCheckmark'} name={"checkmark-q"} size={20} adjust={'fontello'} color='var(--quility-color)'></Icon>
                                                </div>
                                                <div style={{ alignSelf: 'center' }}>
                                                    {productInfo.call_min} Call Minutes
                                                </div>
                                            </>
                                        )}

                                        {productInfo.text_msg !== null && (
                                            <>
                                                <div>
                                                    <Icon className={'qCheckmark'} name={"checkmark-q"} size={20} adjust={'fontello'} color='var(--quility-color)'></Icon>
                                                </div>
                                                <div style={{ alignSelf: 'center' }}>
                                                    {productInfo.text_msg} Text Messages
                                                </div>
                                            </>
                                        )}

                                        {productInfo.multi_media_msg !== null && (
                                            <>
                                                <div>
                                                    <Icon className={'qCheckmark'} name={"checkmark-q"} size={20} adjust={'fontello'} color='var(--quility-color)'></Icon>
                                                </div>
                                                <div style={{ alignSelf: 'center' }}>
                                                    {productInfo.multi_media_msg} Multimedia Messages
                                                </div>
                                            </>
                                        )}

                                        {productInfo.out_going_caller_ids !== null && (
                                            <>
                                                <div>
                                                    <Icon className={'qCheckmark'} name={"checkmark-q"} size={20} adjust={'fontello'} color='var(--quility-color)'></Icon>
                                                </div>
                                                <div style={{ alignSelf: 'center' }}>
                                                    {productInfo.out_going_caller_ids} Outgoing Caller IDs
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {
                                        productInfo.overage_call_min !== null && productInfo.overage_text_msg !== null && productInfo.overage_call_min !== null && (
                                            <div style={{ width: '100%', marginTop: 48 }}>
                                                <span style={{ color: '#2F2F2F', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '700', wordWrap: 'break-word' }}>Overage Rates: </span>

                                                {productInfo.overage_call_min && (
                                                    <div>
                                                        <span style={{ color: '#2F2F2F', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word' }}>Call Minutes — ${productInfo.overage_call_min} / minute over</span>
                                                    </div>
                                                )}

                                                {productInfo.overage_text_msg && (
                                                    <div>
                                                        <span style={{ color: '#2F2F2F', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word' }}>Text Messages — ${productInfo.overage_text_msg} / message segment over</span>
                                                    </div>
                                                )}

                                                {productInfo.overage_multi_media_msg && (
                                                    <div>
                                                        <span style={{ color: '#2F2F2F', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word' }}>Multimedia Messages — ${productInfo.overage_multi_media_msg} per MMS over</span>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        {secret !== false && secret !== true && (
                            <Elements stripe={stripePromise} options={options}>
                                <div className='conentBodyWrapper'>
                                    <div className='contentBodyNestedInputWrapper'>
                                        <div className='contentBodyInputLabelWrapper'>
                                            <div className="contentBodyInputLabel">Name on Card</div>
                                        </div>
                                        <input
                                            type="text"
                                            className="qualifierFieldInput"
                                            value={formData['Name_on_Card']}
                                            onChange={(e) => handleInputChange('Name_on_Card', e.target.value)}
                                        />
                                    </div>
                                    <div className='contentBodyNestedInputWrapper'>
                                        <div className='contentBodyInputLabelWrapper'>
                                            <div className="contentBodyInputLabel">Address</div>
                                        </div>
                                        <input
                                            type="text"
                                            className="qualifierFieldInput"
                                            value={formData['Address']}
                                            onChange={(e) => handleInputChange('Address', e.target.value)}
                                        />
                                    </div>
                                    <div className='contentBodyOuterNestedInputWrapper'>
                                        <div className='contentBodyInnerFlexNestedInputWrapper' style={{ flex: '1 1 0' }}>
                                            <div className='contentBodyInputLabelWrapper'>
                                                <div className="contentBodyInputLabel">City</div>
                                            </div>
                                            <input
                                                className="qualifierFieldInput"
                                                value={formData['City']}
                                                onChange={(e) => handleInputChange('City', e.target.value)}
                                            />
                                        </div>
                                        <div className='contentBodyInnerFlexNestedInputWrapper' style={{ flex: '1 1 0' }}>
                                            <div className='contentBodyInputLabelWrapper'>
                                                <div className="contentBodyInputLabel">State</div>
                                            </div>
                                            <select className='contentBodySelect' style={{ width: 'calc(100% - 10px)' }} onChange={(e) => handleInputChange('State', e.target.value)} value={formData['State']}>
                                                {signupStates.map((state, index) => (
                                                    <option value={state.abbreviation} key={`signupstate${index}`}>{state.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='contentBodyOuterNestedInputWrapper'>
                                        <div className='contentBodyInnerFlexNestedInputWrapper' style={{ flex: '1 1 0' }}>
                                            <div className='contentBodyInputLabelWrapper'>
                                                <div className="contentBodyInputLabel">Zip Code</div>
                                            </div>
                                            <input
                                                style={{ width: 'calc(50% - 30px)' }}
                                                className="qualifierFieldInput"
                                                value={formData['Zip']}
                                                onChange={(e) => handleInputChange('Zip', e.target.value)}
                                            />
                                            { formData['Zip'] !== '' && !/^\d{5}$/.test(formData['Zip']) && (
                                                <div className='conentBodyInputError'>Enter a valid 5 digit zip code.</div>
                                            )}
                                        </div>
                                    </div>

                                    <Payment formData={formData} imoInfo={imoInfo} setLoading={setLoading} submitToStripe={submitToStripe} setSubmitToStripe={setSubmitToStripe} stripePriceId={stripePriceId}/>
                                </div>
                            </Elements>
                        )}
                    </div>

                    <div className='contentWrapper'>
                        <div className='contentTitleWrapper'>
                            <div className='contentTitle'>Terms of Service</div>
                        </div>
                        <div className='contentTermsText' style={{ paddingTop: 16 }}>
                            You agree that you will be billed per month for your subscription to Switchboard Funnel (the “Service”). By placing your order for the Service, you acknowledge that your subscription will auto-renew each month and you authorize Quility Software Applications LLC or one of its affiliates (“Quility”) to charge your credit card or other method of payment monthly for the applicable subscription fee for the Service and for any additional fees related to the usage of the Service, including, without limitation, excess telecommunications charges.
                        </div>
                        <div className='contentTermsText'>
                            In order to cancel your monthly subscription, you must cancel (at least 1 day before the end of the then-current subscription period) by logging into your Service account and canceling your subscription. If you cancel, you will retain access through the end of your billing cycle. To receive a refund for the current billing cycle, you must cancel your subscription within 5 days of the first day of that billing cycle. Each monthly subscription includes the talk, SMS and MMS allocation stated above. Current overage rates for talk, SMS, MMS and additional A2P 10DLC registration are listed above.  You also acknowledge and agree that the monthly subscription fees and additional charges may be changed by Quility from time to time and you agree to be bound by such terms, which will become effective upon email or other notice provided by Quility of any such changes.
                        </div>
                        <div className='contentTermsText'>
                            You also acknowledge and agree that all of your upline agents will have access to information regarding your usage of the Service, including, without limitation, notes, client information and usage history. Finally, you acknowledge and agree that your use of the Service is subject to the Terms of Service linked below.
                        </div>

                        <div onClick={() => setBillingAgreement(!billingAgreement)} className='contentCheckBoxWrapper'>
                            <input
                                type='checkbox'
                                className='contentCheckbox'
                                onChange={() => setBillingAgreement(!billingAgreement)}
                                checked={billingAgreement}
                            />
                            <div>
                                <div style={{ color: 'black', fontSize: 16, fontFamily: 'Montserrat', fontWeight: '400', letterSpacing: 0.09, wordWrap: 'break-word' }}>I agree.</div>
                            </div>
                        </div>

                        <div className='contentViewTermsText'>You will need to view our Terms of Service before you can sign up.</div>
                        <div className='contentViewTermsText' style={{ paddingBottom: 16 }}>Please click the link below.</div>

                        <div className='contentCheckBoxWrapper'>
                            <input
                                type='checkbox'
                                className='contentCheckbox'
                                onChange={handleTermsCheckbox}
                                checked={terms}
                            />
                            <div onClick={showTerms}>
                                <span style={{ color: 'black', fontSize: 16, fontFamily: 'Montserrat', fontWeight: '400', letterSpacing: 0.09, wordWrap: 'break-word' }}>I’ve read and agree to the </span>
                                <span style={{ cursor: 'pointer', color: '#46C3B2', fontSize: 16, fontFamily: 'Montserrat', fontWeight: '400', textDecoration: 'underline', letterSpacing: 0.09, wordWrap: 'break-word' }}>Terms and Conditions</span>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '100%', height: '100%', justifyContent: 'flex-end', alignItems: 'flex-start', gap: 413, display: 'inline-flex', marginBottom: 16 }}>
                        {canSubmit === true ? (
                            <div onClick={() => setSubmitToStripe(!submitToStripe)} style={{ cursor: 'pointer', paddingLeft: 16, paddingRight: 16, paddingTop: 14, paddingBottom: 14, background: '#46C3B2', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', gap: 8, display: 'inline-flex' }}>
                                <div className='contentButtonLabel'>Submit and Subscribe</div>
                            </div>
                        ) : (
                            <div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 14, paddingBottom: 14, background: '#D0D0D0', borderRadius: 5, overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', gap: 8, display: 'inline-flex' }}>
                                <div className='contentButtonLabel'>Submit and Subscribe</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signup;