import React from 'react';

const TermsOfService = ({ terms, setTerms , billingAgreement, setBillingAgreement }) => {
    const toggleTerms = () => {
        setTerms(!terms);
    }

    const handleChange = (e) => {
        if (e.target.checked === true) {
            window.open('https://hq.quility.com/api/public/document/315272/view/general-terms-of-service', '_blank')
        }

        setTerms(!terms);
    }

    const toggleAgreement = () => {
        setBillingAgreement(!billingAgreement)
    }

    return (
        <div>
            <h2>Terms of Service</h2>
            <p>You agree that you will be billed per month for your subscription to Switchboard Funnel (the “Service”).  By placing your order for the Service, you acknowledge that your subscription will auto-renew each month and you authorize Quility Software Applications LLC or one of its affiliates (“Quility”) to charge your credit card or other method of payment monthly for the applicable subscription fee for the Service and for any additional fees related to the usage of the Service, including, without limitation, excess telecommunications charges.</p>
            <p>In order to cancel your monthly subscription, you must cancel (at least [X] days before the end of the then-current subscription period) by logging into your Service account and canceling your subscription. Each monthly subscription includes the talk, sms and mms allocation stated above. Current overage rates for talk, sms, mms and additional A2P 10DLC registration are listed above.  You also acknowledge and agree that the monthly subscription fees and additional charges may be changed by Quility from time to time and you agree to be bound by such terms, which will become effective upon email or other notice provided by Quility of any such changes.</p>
            <p>You also acknowledge and agree that all of your upline agents will have access to information regarding your usage of the Service, including, without limitation, notes, client information and usage history.  Finally, you acknowledge and agree that your use of the Service is subject to the Terms of Service linked below. </p>
            <div className="terms">
                <input
                    type='checkbox'
                    style={{ fontSize: '16px', color: '#008c00' }}
                    onChange={toggleAgreement}
                    checked={billingAgreement}
                />
                <span className="termsLink" style={{textDecoration: 'underline', cursor:'pointer'}} onClick={toggleAgreement}>I agree</span>
            </div>
            <div className="description">You will need to view our Terms of Service before you can sign up. Please click the link below.</div>
            <div className="terms">
                <input
                    type='checkbox'
                    style={{ fontSize: '16px', color: '#008c00' }}
                    onChange={handleChange}
                    checked={terms}
                />
                <a className="termsLink" target="_blank" href='https://hq.quility.com/api/public/document/315272/view/general-terms-of-service' onClick={() => toggleTerms()}>Terms of Service</a>
            </div>
        </div>

    );
};

export default TermsOfService;
